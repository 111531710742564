import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import NotFound from '../images/logo-404.svg'
import '../css/404.css'

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="Page Not Found | Jump Around Gymnastics"
      keywords={['jump around gymnastics']}
      description={`Page not found.`}
      noindex
    />
    <Container>
      <div className="my-8 px-3">
        <img
          className="img-not-found"
          src={NotFound}
          alt="Logo figure with question marks around head"
        />
        <h1 className="mt-8">PAGE NOT FOUND</h1>
        <p>
          Sorry, but we could&apos;t find the page that you were looking for.
          Please try selecting an option from the menu.
        </p>
      </div>
    </Container>
    <Footer></Footer>
    <Copyright></Copyright>
  </Layout>
)

export default NotFoundPage
